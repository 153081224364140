import React from "react";
// Dependencies
import { BsArrowUpRight } from "react-icons/bs";

export default function index() {
  return (
    <div className="flex flex-col justify-start gap-12 py-8 px-20">
      <div className="flex justify-between gap-4 w-full">
        <div className="flex flex-col gap-4 items-start justify-end w-4/12">
          <h5 className="font-light text-xs">
            Rizky Ramadika Trisaputra &#x2022; Apr 24, 2024
          </h5>
          <h2 className="text-4xl font-semibold sm:text-3xl xs:text-3xl">
            Navigating Customer Sentiments: The JNE Experience Through the
            Twitter Lens
          </h2>
          <h3 className="text-sm">
            In the bustling world of e-commerce, where every click and scroll
            can lead to a new transaction, the significance of efficient
            logistics cannot be overstated.
          </h3>
          <a
            href="https://medium.com/@duaempat.e/navigating-customer-sentiments-the-jne-experience-through-the-twitter-lens-0effe2a69335"
            target="_blank"
            rel="noreferrer"
            className="flex gap-1 items-center font-medium text-sm hover:opacity-40 hover:underline hover:underline-offset-2 transition duration-300 cursor-pointer"
          >
            Read more
            <BsArrowUpRight />
          </a>
          <div className="flex gap-1 flex-wrap mt-2">
            <span className="text-xs border rounded-xl py-1 px-4">
              Sentiment Analysis
            </span>
            <span className="text-xs border rounded-xl py-1 px-4">
              Big Data
            </span>
            <span className="text-xs border rounded-xl py-1 px-4">
              Business Strategic
            </span>
            <span className="text-xs border rounded-xl py-1 px-4">
              Logistic
            </span>
            <span className="text-xs border rounded-xl py-1 px-4">
              Data Science
            </span>
          </div>
        </div>
        <div className="w-8/12">
          <img
            src="https://images.unsplash.com/photo-1586528116311-ad8dd3c8310d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="logistics-package"
          />
        </div>
      </div>
      {/* <div className="flex flex-col gap-4 w-full">
        <div>
          <h2 className="text-2xl font-semibold sm:text-xl xs:text-xl mb-2">
            Recent Blog Posts
          </h2>
          <hr />
        </div>
        <div className="flex flex-wrap gap-4 items-start">
            <div></div>
        </div>
      </div> */}
    </div>
  );
}
