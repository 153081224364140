import React from "react";
// Dependencies
import { HashRouter as Router, Route, Routes } from "react-router-dom";
// Components
import BaseLayout from "./components/BaseLayout";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Work from "./pages/Work";
import Blog from './pages/Blog'
// import WorkMore from "./pages/Work/More";
// import Contact from "./pages/Contact";
// import InTouch from "./pages/Contact/GetInTouch";

function App() {
  return (
    <div>
      <Router>
        <BaseLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/work" element={<Work />} />
            <Route path="/blog" element={<Blog />} />
            {/* <Route path="/more/:id" element={<WorkMore />} /> */}
            {/* <Route path="/contact" element={<Contact />} /> */}
            {/* <Route path="/touch" element={<InTouch />} /> */}
          </Routes>
        </BaseLayout>
      </Router>
    </div>
  );
}

export default App;
