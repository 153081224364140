import React from "react";
// Components
import Footer from "./Footer";
import NavBar from "./NavBar";

export default function index(props: any) {
  return (
    <div>
      <NavBar />
      {props.children}
      <Footer />
    </div>
  );
}
