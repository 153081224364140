import React, { createContext } from "react";

//Interface for GlobalContext
interface GlobalContextInterface {}

//Create context which will behave as a global variable
export const GlobalContext = createContext<GlobalContextInterface>({});

//Props for component
interface Props {
  children: React.ReactElement;
}

export const GlobalContextProvider: React.FC<Props> = ({ children }) => {
  return <GlobalContext.Provider value={{}}>{children}</GlobalContext.Provider>;
};
