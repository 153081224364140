import React from "react";
// Dependencies
// import { NavLink } from "react-router-dom";
import { MdWeb } from "react-icons/md";
import { FiArrowUpRight } from "react-icons/fi";
// import { FaMobileAlt } from "react-icons/fa";
import { GiCrafting } from "react-icons/gi";
import { SiSmartthings } from "react-icons/si";
import RunningText from "../../components/RunningText";

export default function Index() {
  const texts = [
    "PROJECTS DONE +8",
    "YEAR OF EXPERIENCE +4",
    "PROFESSIONAL CERTIFICATIONS +4",
    "SUCCESSFUL LAUNCHES +10",
  ];

  return (
    <div className="services transition-opacity duration-500">
      <div className="banner h-[85vh] flex flex-col items-center justify-center text-[#c8d6e5] leading-7">
        <h2 className="tracking-wider">
          Started with a&nbsp;
          <span className="font-semibold text-[#d63031]">problem</span>
        </h2>
        <h1 className="tracking-wider">
          it's time to create&nbsp;
          <span className="font-semibold text-[#cffa63]">solutions</span> to
          conquer that problem
        </h1>
      </div>
      <div className="gap-24 flex flex-col items-center justify-end h-screen">
        <div>
          <div className="text-center">
            <h2 className="font-semibold text-2xl uppercase tracking-wider">
              What I Offer
            </h2>
            <h4 className="text-sm font-light tracking-wide">
              <span>
                My aim is to build strong, long-term connections, and succeed
                together
              </span>
            </h4>
          </div>
          <div className="flex items-center justify-center mt-12 gap-8">
            <div className="flex flex-col items-center text-center w-60 p-4 justify-start h-44 gap-4">
              <div>
                <MdWeb size={50} />
              </div>
              <div>
                <h2 className="text-base font-semibold">Web Development</h2>
                <h6 className="text-sm font-light">
                  Unlock the potential of the web with cutting-edge applications
                  designed to elevate your online presence.
                </h6>
              </div>
            </div>
            {/* <div className="flex flex-col items-center text-center w-60 p-4 justify-start h-44 gap-4">
              <div>
                <FaMobileAlt size={50} />
              </div>
              <div>
                <h2 className="text-base font-semibold">Mobile Apps</h2>
                <h6 className="text-sm font-light">
                  Empower your brand on the go with custom mobile applications
                  that engage and retain your audience.
                </h6>
              </div>
            </div> */}
            <div className="flex flex-col items-center text-center w-60 p-4 justify-start h-44 gap-4">
              <div>
                <SiSmartthings size={50} />
              </div>
              <div>
                <h2 className="text-base font-semibold">IoT Development</h2>
                <h6 className="text-sm font-light">
                  Connect and control with smart IoT solutions that bring
                  efficiency and intelligence to your operations.
                </h6>
              </div>
            </div>
            <div className="flex flex-col items-center text-center w-60 p-4 justify-start h-44 gap-4">
              <div>
                <GiCrafting size={50} />
              </div>
              <div>
                <h2 className="text-base font-semibold">Product Management</h2>
                <h6 className="text-sm font-light">
                  Navigate your product's lifecycle with expertise and strategic
                  insight for continuous growth.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <RunningText texts={texts} />
          {/* <div className="text-sm font-light flex flex-col items-center justify-center uppercase secondary-banner h-[35vh]">
            <h4 className="tracking-widest leading-8 text-white">
              Good Vision <b>requires</b> right person
            </h4>
            <NavLink
              to="/touch"
              className="underline underline-offset-4 text-[#d63031] tracking-wider font-semibold"
            >
              Let's talk
            </NavLink>
          </div> */}
          <div className="flex justify-center items-center h-[35vh] p-8 gap-16">
            <div className="flex flex-col gap-4">
              <h2 className="font-bold text-6xl">
                Let's get <br /> in touch
              </h2>
              <h4 className="text-sm font-light">
                Feel free to reach out to me with your thoughts, <br />
                and I look forward to our next steps
              </h4>
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <span className="text-sm font-light">Email</span>
                <a
                  href="mailto:ramadika1050@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-40 transition duration-300 flex items-center gap-1 text-sm font-semibold"
                >
                  ramadika1050@gmail.com <FiArrowUpRight size={20} />
                </a>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-sm font-light">LinkedIn</span>
                <a
                  href="https://www.linkedin.com/in/rizkyramadika/"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-40 transition duration-300 flex items-center gap-1 text-sm font-semibold"
                >
                  linkedin.com/in/rizkyramadika/ <FiArrowUpRight size={20} />
                </a>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-sm font-light">Location</span>
                <span className="text-sm font-semibold">
                  &#x1F1EE;&#x1F1E9; Jakarta, Indonesia
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
