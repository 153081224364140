import React from "react";
// Dependencies
import { NavLink } from "react-router-dom";
// Components
import SocialMedia from "../../components/SocialMedia";

export default function index() {
  const imgHome =
    "https://res.cloudinary.com/dp5hwzmhm/image/upload/v1706966821/jezael-melgoza-cv3T6MG36DE-unsplash-min_p4zguw.jpg";

  return (
    <div className="transition-opacity duration-500">
      <div className="w-screen bg-[#f8f9fa] mt-16 home">
        <div className="flex-col">
          <div
            className="h-[180px] p-4 banner flex justify-center"
            style={{
              backgroundImage:
                "linear-gradient(grey, grey), url(" + imgHome + ")",
            }}
          >
            <h2 className="leading-[6rem] mt-8">
              <span>He-</span>
              <span>llo.</span>
            </h2>
          </div>
          <div className="py-12 mt-10 text-[#2d3436] text-center tracking-wider">
            <h5>I'm Rizky Ramadika</h5>
            <h6>
              Check out more about me&nbsp;
              <NavLink
                to="/about"
                className="text-[#d63031] hover:text-[#ff7979]"
              >
                here
              </NavLink>
            </h6>
          </div>
        </div>
      </div>
      <div className="mt-8 px-4">
        <SocialMedia />
      </div>
    </div>
  );
}
