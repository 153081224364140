import React from "react";
// Dependencies
import { FaTwitter, FaLinkedin, FaMediumM } from "react-icons/fa";
import { AiFillInstagram, AiFillGithub } from "react-icons/ai";
import { contact_info } from "../../store/constants";

export default function index() {
  const {
    github_account,
    twitter_account,
    instagram_account,
    linkedin_account,
    medium_account,
  } = contact_info;

  return (
    <div className="flex justify-center items-center gap-12">
      <a
        href={github_account}
        target="_blank"
        rel="noreferrer"
        className="hover:opacity-40 transition duration-300"
      >
        <AiFillGithub size={20} className="text-[#646464]" />
      </a>
      <a
        href={twitter_account}
        target="_blank"
        rel="noreferrer"
        className="hover:opacity-40 transition duration-300"
      >
        <FaTwitter size={20} className="text-[#646464]" />
      </a>
      <a
        href={instagram_account}
        target="_blank"
        rel="noreferrer"
        className="hover:opacity-40 transition duration-300"
      >
        <AiFillInstagram size={20} className="text-[#646464]" />
      </a>
      <a
        href={linkedin_account}
        target="_blank"
        rel="noreferrer"
        className="hover:opacity-40 transition duration-300"
      >
        <FaLinkedin size={20} className="text-[#646464]" />
      </a>
      <a
        href={medium_account}
        target="_blank"
        rel="noreferrer"
        className="hover:opacity-40 transition duration-300"
      >
        <FaMediumM size={20} className="text-[#646464]" />
      </a>
    </div>
  );
}
