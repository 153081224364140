export const projects = [
  {
    _id: "6",
    title: "Pena - Speech to Text",
    role: "Product Manager | Product Engineer",
    place: "-",
    year: "2024",
    status: "Prototype",
    detail:
      "In a fast-changing and highly competitive business world, efficient inventory and asset management is key to maintaining smooth operations and maximizing profits. However, many companies still face significant challenges in this regard. Some common issues include difficulty tracking inventory in real-time, loss or misuse of assets, inefficiencies in resource management, and data inaccuracies and inconsistencies.",
    thumbnail:
      "https://plus.unsplash.com/premium_photo-1678734268616-015d5879b8c2?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    skillset: ["Product Management", "Analytical Skill", "Product Development"],
    banner: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1TG2LfcRg7iJ3NmUGN7JcsmgkPROkG6r8",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=1eFdsFRDahFy0fkG1RuWny5EdsfYbNAcm",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1HYYpYIeUuDcMlUx_YxcyChutmTbIiv3E",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1amDaKhfX8UwjmSjgXpiyC_Yz8eEIJJMu",
      },
      {
        _id: 5,
        image_url:
          "https://drive.google.com/uc?export=view&id=14d9xwfvc2w0Lf2CTar-CKlCvL6wERTQf",
      },
    ],
    image: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1aOxJYh4XctOWaMwAEb4YP5QaJSzRLzOi",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=1XMDbt6IP6UZG_z8KAwcLt6SEbfDVn10l",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1BPo76Lxwg3KUBFMrtfmmwJjQf18R6AU-",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1AQplGufHk34LJye-l-l6a2SuOwcChGc8",
      },
    ],
    description: [
      {
        _id: 1,
        paragraph:
          "To be able to send a position information, each person will be given a tool that can provide their position information in real time. The technology used to support the mapping service system that was built was Bluetooth Low Energy (BLE). The system built consists of a node sensing (BLE Slave) that will read the wearable device (BLE Master) used by people. The reading results from the node sensing will be sent to the server for shown.",
      },
      {
        _id: 2,
        paragraph:
          "Position information of each person can be seen in the form of an area name or a location name for a large name. Position information is obtained or received from the reading by node sensing installed in each area or location of the place. Position information can be accessed by everyone in the form of a web application, so that everyone can find out real time position information for themselves and everyone else in that place whenever and wherever.",
      },
    ],
    link: "https://ramadika.github.io/User-Monitoring/#/",
  },
  {
    _id: "5",
    title: "Marine Survey Inventory Management Platform",
    role: "Product Manager",
    place: "PT. EGS Indonesia",
    year: "2024",
    status: "Prototype",
    detail:
      "In a fast-changing and highly competitive business world, efficient inventory and asset management is key to maintaining smooth operations and maximizing profits. However, many companies still face significant challenges in this regard. Some common issues include difficulty tracking inventory in real-time, loss or misuse of assets, inefficiencies in resource management, and data inaccuracies and inconsistencies.",
    thumbnail: "https://www.egssurvey.com/images/Bold%20Explorer%202.png",
    skillset: ["Product Management", "Analytical Skill", "Product Development"],
    banner: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1TG2LfcRg7iJ3NmUGN7JcsmgkPROkG6r8",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=1eFdsFRDahFy0fkG1RuWny5EdsfYbNAcm",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1HYYpYIeUuDcMlUx_YxcyChutmTbIiv3E",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1amDaKhfX8UwjmSjgXpiyC_Yz8eEIJJMu",
      },
      {
        _id: 5,
        image_url:
          "https://drive.google.com/uc?export=view&id=14d9xwfvc2w0Lf2CTar-CKlCvL6wERTQf",
      },
    ],
    image: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1aOxJYh4XctOWaMwAEb4YP5QaJSzRLzOi",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=1XMDbt6IP6UZG_z8KAwcLt6SEbfDVn10l",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1BPo76Lxwg3KUBFMrtfmmwJjQf18R6AU-",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1AQplGufHk34LJye-l-l6a2SuOwcChGc8",
      },
    ],
    description: [
      {
        _id: 1,
        paragraph:
          "To be able to send a position information, each person will be given a tool that can provide their position information in real time. The technology used to support the mapping service system that was built was Bluetooth Low Energy (BLE). The system built consists of a node sensing (BLE Slave) that will read the wearable device (BLE Master) used by people. The reading results from the node sensing will be sent to the server for shown.",
      },
      {
        _id: 2,
        paragraph:
          "Position information of each person can be seen in the form of an area name or a location name for a large name. Position information is obtained or received from the reading by node sensing installed in each area or location of the place. Position information can be accessed by everyone in the form of a web application, so that everyone can find out real time position information for themselves and everyone else in that place whenever and wherever.",
      },
    ],
    link: "https://ramadika.github.io/User-Monitoring/#/",
  },
  {
    _id: "4",
    title: "Optical Distribution Tracking System using NFC",
    role: "Product Manager",
    place: "Kadaka",
    year: "2023",
    status: "Prototype",
    detail:
      "In a fast-changing and highly competitive business world, efficient inventory and asset management is key to maintaining smooth operations and maximizing profits. However, many companies still face significant challenges in this regard. Some common issues include difficulty tracking inventory in real-time, loss or misuse of assets, inefficiencies in resource management, and data inaccuracies and inconsistencies.",
    thumbnail:
      "https://www.shipbob.com/wp-content/uploads/2021/07/inventory-vs-stock-.jpg",
    skillset: ["Product Management", "Analytical Skill", "Product Development"],
    banner: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1TG2LfcRg7iJ3NmUGN7JcsmgkPROkG6r8",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=1eFdsFRDahFy0fkG1RuWny5EdsfYbNAcm",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1HYYpYIeUuDcMlUx_YxcyChutmTbIiv3E",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1amDaKhfX8UwjmSjgXpiyC_Yz8eEIJJMu",
      },
      {
        _id: 5,
        image_url:
          "https://drive.google.com/uc?export=view&id=14d9xwfvc2w0Lf2CTar-CKlCvL6wERTQf",
      },
    ],
    image: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1aOxJYh4XctOWaMwAEb4YP5QaJSzRLzOi",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=1XMDbt6IP6UZG_z8KAwcLt6SEbfDVn10l",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1BPo76Lxwg3KUBFMrtfmmwJjQf18R6AU-",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1AQplGufHk34LJye-l-l6a2SuOwcChGc8",
      },
    ],
    description: [
      {
        _id: 1,
        paragraph:
          "To be able to send a position information, each person will be given a tool that can provide their position information in real time. The technology used to support the mapping service system that was built was Bluetooth Low Energy (BLE). The system built consists of a node sensing (BLE Slave) that will read the wearable device (BLE Master) used by people. The reading results from the node sensing will be sent to the server for shown.",
      },
      {
        _id: 2,
        paragraph:
          "Position information of each person can be seen in the form of an area name or a location name for a large name. Position information is obtained or received from the reading by node sensing installed in each area or location of the place. Position information can be accessed by everyone in the form of a web application, so that everyone can find out real time position information for themselves and everyone else in that place whenever and wherever.",
      },
    ],
    link: "https://ramadika.github.io/User-Monitoring/#/",
  },
  {
    _id: "3",
    title: "Indonesian Air Force Training Management System",
    role: "Full Stack Web Developer",
    place: "TNI - AU",
    year: "2022",
    status: "",
    detail:
      "Indonesian Air Force Training Management System is faced with the problem of lack of information management system for their training process.",
    thumbnail:
      "https://upload.wikimedia.org/wikipedia/commons/4/46/Marsekal_TNI_Fadjar_Prasetyo%2C_S.E.%2C_M.P.P.%2C_CSFA.%2C_dengan_Crew_Dinamic_Show_Team.jpg",
    skillset: ["Web Development", "Agile Methodology"],
    banner: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1A-8K6x5WXeEsFVkRhpNvdz1QmdGeDHsx",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=1hiDqfGk5_xC6pUX0x3xrHbDYwnXiW69C",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1X-5fGczoirfNKbtW1HA9ICmhD7Qk9B4m",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1i2SodztWdFlk0nkdYaswcU9MMvwfbgd1",
      },
      {
        _id: 5,
        image_url:
          "https://drive.google.com/uc?export=view&id=1uDXouCBv-UJpYfzQC20bSzJHRMmJMt8i",
      },
    ],
    image: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1kzUzEG7OGF4MP3OwdsDJAuvXGCQ9GMtl",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=19FfDVwSreHeWDIxmsQnoTK_A5YzcnVNS",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1WrJUSKsNTq8QyZ3H6S8iX8tzI3I-CmF_",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1lKRW0v4kTobVAIZePDrRaoWmuK4Jtj8V",
      },
    ],
    description: [
      {
        _id: 1,
        paragraph:
          "NFC technology is used to control production. NFC tags are used for product labeling and for reading NFC tags using the user's smartphone and NFC reader.",
      },
      {
        _id: 2,
        paragraph:
          "Production process information can be viewed by using web-based application. Information that provided includes dashboard of summary production, warehouse information, add production data, and view all data. The features that provided can be accessed according the user access.",
      },
    ],
    link: "http://rfard.890m.com/",
  },
  {
    _id: "2",
    title: "Production Control System using NFC",
    role: "Full Stack Developer",
    place: "PT. Tri Cipta Teknindo",
    year: "2021",
    status: "",
    detail:
      "PT. Tri Cipta Teknindo is faced with the problem of lack of information on a production process. From these problems, a Production Control System was created by utilizing NFC technology. So that it can provide real-time information from the assembling process, the data collection process, the quality check process, to the last on the packaging and shipping process.",
    thumbnail:
      "https://ciptateknindo.com/wp-content/uploads/2022/11/IMG_20140922_091508-scaled-e1669712869626.jpg",
    skillset: ["Web Development", "Agile Methodology", "Mobile Development"],
    banner: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1A-8K6x5WXeEsFVkRhpNvdz1QmdGeDHsx",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=1hiDqfGk5_xC6pUX0x3xrHbDYwnXiW69C",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1X-5fGczoirfNKbtW1HA9ICmhD7Qk9B4m",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1i2SodztWdFlk0nkdYaswcU9MMvwfbgd1",
      },
      {
        _id: 5,
        image_url:
          "https://drive.google.com/uc?export=view&id=1uDXouCBv-UJpYfzQC20bSzJHRMmJMt8i",
      },
    ],
    image: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1kzUzEG7OGF4MP3OwdsDJAuvXGCQ9GMtl",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=19FfDVwSreHeWDIxmsQnoTK_A5YzcnVNS",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1WrJUSKsNTq8QyZ3H6S8iX8tzI3I-CmF_",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1lKRW0v4kTobVAIZePDrRaoWmuK4Jtj8V",
      },
    ],
    description: [
      {
        _id: 1,
        paragraph:
          "NFC technology is used to control production. NFC tags are used for product labeling and for reading NFC tags using the user's smartphone and NFC reader.",
      },
      {
        _id: 2,
        paragraph:
          "Production process information can be viewed by using web-based application. Information that provided includes dashboard of summary production, warehouse information, add production data, and view all data. The features that provided can be accessed according the user access.",
      },
    ],
    link: "http://rfard.890m.com/",
  },
  {
    _id: "1",
    title: "People Mapping using BLE",
    place: "Taman Mini Indonesia Indah",
    year: "2019",
    role: "Product owner",
    status: "Prototype",
    detail:
      "In a large place are often faced with the problem of someone who do not know the position of the person they are trying to find. Problems that occur are part of the problem of identifying the position of people in a large place. People mapping service system was built in such a large place to overcome the problem of identifying where people are in a wide area",
    thumbnail:
      "https://tamanmini.com/taman_jelajah_indonesia/wp-content/uploads/2023/07/banner-4.jpg",
    skillset: ["Analytical Skill", "Product Development"],
    banner: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1TG2LfcRg7iJ3NmUGN7JcsmgkPROkG6r8",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=1eFdsFRDahFy0fkG1RuWny5EdsfYbNAcm",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1HYYpYIeUuDcMlUx_YxcyChutmTbIiv3E",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1amDaKhfX8UwjmSjgXpiyC_Yz8eEIJJMu",
      },
      {
        _id: 5,
        image_url:
          "https://drive.google.com/uc?export=view&id=14d9xwfvc2w0Lf2CTar-CKlCvL6wERTQf",
      },
    ],
    image: [
      {
        _id: 1,
        image_url:
          "https://drive.google.com/uc?export=view&id=1aOxJYh4XctOWaMwAEb4YP5QaJSzRLzOi",
      },
      {
        _id: 2,
        image_url:
          "https://drive.google.com/uc?export=view&id=1XMDbt6IP6UZG_z8KAwcLt6SEbfDVn10l",
      },
      {
        _id: 3,
        image_url:
          "https://drive.google.com/uc?export=view&id=1BPo76Lxwg3KUBFMrtfmmwJjQf18R6AU-",
      },
      {
        _id: 4,
        image_url:
          "https://drive.google.com/uc?export=view&id=1AQplGufHk34LJye-l-l6a2SuOwcChGc8",
      },
    ],
    description: [
      {
        _id: 1,
        paragraph:
          "To be able to send a position information, each person will be given a tool that can provide their position information in real time. The technology used to support the mapping service system that was built was Bluetooth Low Energy (BLE). The system built consists of a node sensing (BLE Slave) that will read the wearable device (BLE Master) used by people. The reading results from the node sensing will be sent to the server for shown.",
      },
      {
        _id: 2,
        paragraph:
          "Position information of each person can be seen in the form of an area name or a location name for a large name. Position information is obtained or received from the reading by node sensing installed in each area or location of the place. Position information can be accessed by everyone in the form of a web application, so that everyone can find out real time position information for themselves and everyone else in that place whenever and wherever.",
      },
    ],
    link: "https://ramadika.github.io/User-Monitoring/#/",
  },
  // {
  //   _id: "3",
  //   title: "Inventory Report",
  //   role: "Full Stack Developer",
  //   status: "Prototype",
  //   detail:
  //     "The ODP Report System is made for internet operators who are not aware of the situation and condition of their equipment (ODP) in the field. The system is divided into a website platform and a mobile platform. The website platform contains reports on the results of operations in the field using the mobile platform.",
  //   thumbnail:
  //     "https://drive.google.com/uc?export=view&id=11S1r757TU-KpeS-YiIMkTR1xQZ3d8mM5",
  //   banner: [
  //     {
  //       _id: 1,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=16C763EqrxlQI2qotnCdDKJAERVa6zG-3",
  //     },
  //     {
  //       _id: 2,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1WgKPli8WMrQPf7GuiAaGBAxou1_qNew7",
  //     },
  //     {
  //       _id: 3,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=10QjFjapyKUdnyyYfgfNIbbWkzjyRqNT7",
  //     },
  //     {
  //       _id: 4,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1cocjn5aGRWt7QSfuVYWVSBcl40pTNRiw",
  //     },
  //     {
  //       _id: 5,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1KgWtc4l17I2RREGhcZiZQiRJTsGiRRJ0",
  //     },
  //   ],
  //   image: [
  //     {
  //       _id: 1,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1yXUAM_7u57r4TZiM03JIywZ06p8kmG9d",
  //     },
  //     {
  //       _id: 2,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1tXM1HQkySjZ2DvEX7FVPEYqzZ307y-tr",
  //     },
  //     {
  //       _id: 3,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1N1oex8xguIwpr-JqXwcpQfVXR9s5-MuC",
  //     },
  //     {
  //       _id: 4,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1oMX6C7wv5d_1dmY57cwIltP_8DJs42gP",
  //     },
  //   ],
  //   description: [
  //     {
  //       _id: 1,
  //       paragraph:
  //         "Product information system digitization is developed using the IoT concept. The IoT technology used is NFC, where the NFC tag is used as a label for the product and the user's smartphone as a product label (NFC tag) reader.",
  //     },
  //     {
  //       _id: 2,
  //       paragraph:
  //         "Product information can be viewed in the form of a web-based application. The data is obtained from the operation process in the field. The information displayed includes data for each product, customer data, product mapping data, number of products, product classification, and daily summaries of products.",
  //     },
  //   ],
  //   link: "https://ramadika.github.io/ODP-Report/#/",
  // },
  // {
  //   _id: "4",
  //   title: "NFC - Field Operation App",
  //   role: "Mobile Developer",
  //   status: "Prototype",
  //   detail:
  //     "One of the ODP reporting systems is a mobile platform that is used for field operations. The mobile platform uses NFC technology to help facilitate operations in the field, which has been a problem for internet operators.",
  //   thumbnail:
  //     "https://drive.google.com/uc?export=view&id=11GpVd5H2wq7bJawZc4VkXyviP5OegAR2",
  //   banner: [
  //     {
  //       _id: 1,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=11MCop_YIOsDT_oODWDjV5tiXLCT_JZ26",
  //     },
  //     {
  //       _id: 2,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1K8eA9D7KemsdVP-UcRFaY85TNL7s0pF-",
  //     },
  //     {
  //       _id: 3,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1nqRryQUJXwcZqvsHEgwPtLKO4NLmTqom",
  //     },
  //     {
  //       _id: 4,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1cqEGowPlFotv-Vf11ozXS2xNZzhY2NUf",
  //     },
  //     {
  //       _id: 5,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=19eZCu5um4TgoPKrzgqqHDpxYjih3HOxh",
  //     },
  //   ],
  //   image: [
  //     {
  //       _id: 1,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1yXUAM_7u57r4TZiM03JIywZ06p8kmG9d",
  //     },
  //     {
  //       _id: 2,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1tXM1HQkySjZ2DvEX7FVPEYqzZ307y-tr",
  //     },
  //     {
  //       _id: 3,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1N1oex8xguIwpr-JqXwcpQfVXR9s5-MuC",
  //     },
  //     {
  //       _id: 4,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1wz-iHIYl7-g7m98PYM4_d19Zi030sT4W",
  //     },
  //   ],
  //   description: [
  //     {
  //       _id: 1,
  //       paragraph:
  //         "Product that installed have NFC tag as a label for that product. To read that label (NFC tag), user of field operation uses their smartphones that already support the NFC feature",
  //     },
  //     {
  //       _id: 2,
  //       paragraph:
  //         "Mobile Application have 2 features to support the Product Information System. To enter or update the data of product, the user must first scan the label (NFC tag). User also can see the scanned product information. View Data feature can accessed without have to scan the label (NFC tag). This mobile application is integrated with Inventory Report Application. So, the people can see the product information in the form of a web-based application.",
  //     },
  //   ],
  //   link: "https://www.figma.com/proto/WUg2N57c2TgqhZWnc4uBCJ/Mobile-Product?node-id=0%3A3&scaling=min-zoom",
  // },
  // {
  //   _id: "5",
  //   title: "Arnawa Project",
  //   role: "UI / UX Designer",
  //   detail:
  //     "Project Arnawa need UI/UX Design for their web-based online store brand project.",
  //   thumbnail:
  //     "https://drive.google.com/uc?export=view&id=1-CgIYnbyk0pUQ4Nz6UfaCFvMGSzHGftW",
  //   banner: [
  //     {
  //       _id: 1,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1h9jB9tNPDq-oM6zXTSO-DnAALDEr_uDp",
  //     },
  //     {
  //       _id: 2,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1EvLGEpS0upw2liMZeROjVxjsTCMcKS9V",
  //     },
  //     {
  //       _id: 3,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1nTgDCwr6GmUahfuzPVW8FbTgByazIPKE",
  //     },
  //     {
  //       _id: 4,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1JZnQTTuF_xnaoIHmUo7_a09sx1gk34jX",
  //     },
  //     {
  //       _id: 5,
  //       image_url:
  //         "https://drive.google.com/uc?export=view&id=1hAbmT5dihEkROuGbLIyF9w3jKsyllKWu",
  //     },
  //   ],
  //   image: [],
  //   description: [],
  //   link: "https://www.figma.com/proto/RGrMQzWkoSLsZxQQLcb80L/OnlineStore-Product?node-id=2%3A249&scaling=min-zoom",
  // },
];

export const education = [
  {
    year: "2015 - 2019",
    major: "Bachelor of Computer Science in School of Computing",
    place: "Telkom University Bandung, Indonesia",
    thumbnail:
      "https://telkomuniversity.ac.id/wp-content/uploads/2019/03/GEDUNG-BANGKIT.jpg",
    degree: "Bachelor",
  },
  {
    year: "2023 - Now",
    major:
      "Master of Business Administration in Faculty of Economic and Business",
    place: "Padjadjaran University Bandung, Indonesia",
    thumbnail:
      "https://www.quipper.com/id/blog/wp-content/uploads/2017/01/c9cd3-image-1-4.jpg",
    degree: "Master",
  },
];

export const skillset = [
  {
    category: "Web Development",
    item: "HTML, CSS, SCSS, Javascript, ReactJS, Typescript, NodeJS, PHP",
  },
  {
    category: "Frontend Development",
    item: "HTML, CSS, SCSS, Javascript, ReactJS, Typescript, NextJS",
  },
  {
    category: "Mobile Development",
    item: "Kotlin, Java",
  },
  {
    category: "IoT Development",
    item: "C++",
  },
  {
    category: "Data Visualization",
    item: "Echarts, d3",
  },
  {
    category: "Product Management",
    item: "Echarts, d3",
  },
  {
    category: "Database",
    item: "Oracle, MySQL",
  },
  {
    category: "Design",
    item: "Figma, Adobe Photoshop",
  },
  {
    category: "Version Control",
    item: "Git",
  },
];

export const work_experiences = [
  {
    icon: "https://inadigital.co.id/images/ina-digital-og.png",
    company: "INA Digital",
    time: "Feb 2023 - Now",
    role: "Frontend Engineer",
    desc: "Develop and maintain product and dashboards based on the user journey and responsible for any other Software Engineering related tasks",
  },
  {
    icon: "https://assets-global.website-files.com/63f6cd0a01cd2e19af1f8af6/6406eda5be87b10577ecf770_628f45b1a33b077dcfe991a6_22.jpeg",
    company: "Liquidx.studio",
    time: "Sept 2020 - Dec 2022",
    role: "Frontend Developer",
    desc: "Develop and maintain product based on the user journey by applying the concept of NFT-Blockchain and responsible for any other Software Engineering related tasks",
  },
  {
    icon: "https://media.licdn.com/dms/image/C560BAQEZPgCgMq0Brw/company-logo_200_200/0/1630631705321/pt_tri_cipta_teknindo_logo?e=2147483647&v=beta&t=EYSQi8Wet1EOOsR6Yqrod4nf0gfoJrPwosyPd19zpCM",
    company: "PT. Tri Cipta Teknindo",
    time: "Nov 2020 - Sept 2021",
    role: "Device Engineer",
    desc: "Develop product or a solution to problems by applying the concept of IoT",
  },
  {
    icon: "https://media.licdn.com/dms/image/C560BAQHU95efGMk6lw/company-logo_200_200/0/1674645358812?e=2147483647&v=beta&t=WHjaGTfWwtdQ-OCXxsIm82e5WmkjKWnW7aR5UWcyDNE",
    company: "PT. Phiraka Sinergi Indonesia",
    time: "Aug 2019 - Aug 2020",
    role: "Web Developer",
    desc: "Develop and maintain product according to user requirements whether it UI/UX design or server and database",
  },
  {
    icon: "https://smktelkom-mlg.sch.id/assets/upload/image/thumbs/1599277422_dds.png",
    company: "Telkom Divisi Digital Service",
    time: "May 2018 - Aug 2018",
    role: "Internship",
    desc: "Help researchers done the research.",
  },
];

export const accomplishments = [
  {
    place: "School of Computing Telkom University",
    time: "2018",
    role: "Internet of Things Assistant",
    desc: "Responsible for helping participants directly to achieve the desired goals",
  },
  {
    place: "Institut Teknologi Sepuluh Nopember, Surabaya",
    time: "2017",
    role: "YES SUMMIT ASEAN 2017 Participant",
    desc: "Expected to be able to develop knowledge, creativity, and critical thinking, in line with the theme of the conference “ASEAN Connectivity”",
  },
];

export const course_n_sertification = [
  {
    id: 8,
    title: "Ajou GSIS-UNPAD Short-term MBA",
    period: "Aug 2024",
    institute: "Ajou University",
    desc: "Completed an intensive MBA program focusing on global business strategies and management, offered in collaboration between Ajou University and UNPAD.",
  },
  {
    id: 7,
    title: "Docker Essentials Workshop",
    period: "Jun 2020",
    institute: "INA Digital - Govtech Indonesia",
    desc: "Developed a foundational understanding of Docker, focusing on containerization concepts, deployment strategies, and optimizing applications for scalable environments.",
  },
  {
    id: 6,
    title: "HPKE in Action",
    period: "May 2020",
    institute:
      "Digital Transformation Office (DTO) Ministry of Health Indonesia",
    desc: "Gained hands-on experience with Hybrid Public Key Encryption (HPKE), learning its practical applications in securing digital communications within healthcare systems.",
  },
  {
    id: 5,
    title: "Mastering Customer Data Analysis: Insights to Drive Growth",
    period: "Feb 2024",
    institute: "Kelas.work",
    desc: "Acquired advanced skills in customer data analysis, focusing on extracting actionable insights to inform strategic business decisions and drive growth.",
  },
  {
    id: 4,
    title: "Harvardx: Data Science, Visualization",
    period: "Jan 2024 — Feb 2024",
    institute: "Edx",
    desc: "Gained proficiency in data visualization techniques using R, enabling the creation of insightful and visually compelling representations of complex datasets.",
  },
  {
    id: 1,
    title: "Intensive Product Management Course",
    period: "Jan 2023 - Jun 2023",
    institute: "Purwadhika Digital School",
    desc: "As a Product Manager in the program, I navigated the entire product lifecycle, refining skills in market analysis, user experience design, and agile methodologies. The immersive learning environment provided a holistic view of the product landscape, empowering me to drive innovation and craft impactful solutions.",
  },
  // {
  //   id: 2,
  //   title: "Introduction to Product Management",
  //   period: "Jan 2023",
  //   institute: "Revou",
  //   role: "Product Manager",
  //   desc: "This introductory course at Revou served as a catalyst for my passion in product management, laying the groundwork for a future of innovation and strategic product development.",
  // },
  {
    id: 3,
    title: "Agile vs Waterfall Project Management",
    period: "Nov 2020",
    institute: "The Agile Project Management Academy",
    desc: "In this comprehensive program, I gained a deep understanding of both Agile and Waterfall methodologies, empowering me with the versatility to adapt project management approaches based on project requirements.",
  },
];

export const organization_experiences = [
  {
    bg_img:
      "https://res.cloudinary.com/dp5hwzmhm/image/upload/v1707567227/abigail-keenan-8-s5QuUBtyM-unsplash_wnw2rh.jpg",
    period: "Aug 2017 - Aug 2019",
    organization: "FIFA Telkom University",
    role: "Permit and Equipment Coordinator",
    desc: "As the Permit and Equipment Coordinator, I ensure our organization meets the equipment and manage permissions for players and teams of our community in futsal and soccer.",
  },
  {
    bg_img:
      "https://res.cloudinary.com/dp5hwzmhm/image/upload/v1707569183/mikael-kristenson-3aVlWP-7bg8-unsplash_as6kdh.jpg",
    period: "Aug 2016 - Desc 2016",
    organization: "I-Core P16",
    role: "Organize Committee",
    desc: "As an Organize Committee member, my responsibility revolves around meeting the event's equipment and permission requirements. I ensure that all necessary equipment is available and manage the permissions required for the smooth execution of the event. ",
  },
  {
    bg_img:
      "https://res.cloudinary.com/dp5hwzmhm/image/upload/v1707569379/hadi-utama-qVJQx90xARI-unsplash_awrhgp.jpg",
    period: "Aug 2015 - Now",
    organization: "IKMASS Telkom University",
    role: "Community Member",
    desc: "As a community member of IKMASS (Ikatan Mahasiswa Sumatera Selatan) at Telkom University, I actively participate in various community activities and initiatives. Whether it's organizing events, coordinating projects, or supporting fellow members, I am dedicated to contributing to the growth and success of our community. ",
  },
];

export const contact_info = {
  full_name: "Rizky Ramadika Trisaputra",
  first_name: "Rizky",
  middle_name: "Ramadika",
  last_name: "Trisaputra",
  nick_name: "Eki",
  email: "ramadika1050@gmail.com",
  phone_number: "+6282218358955",
  location: "https://maps.app.goo.gl/wPcqMmBj7NAdubo69",
  github_account: "https://github.com/ramadika",
  twitter_account: "https://twitter.com/akidamarykzir",
  instagram_account: "https://www.instagram.com/duaempat.e/",
  linkedin_account: "https://www.linkedin.com/in/rizkyramadika/",
  medium_account: "https://medium.com/@duaempat.e",
  calendly_account: "https://calendly.com/ramadika/60min",
};

export const GEOLOCATION_URL = "http://ip-api.com/json";
