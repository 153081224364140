// Dependencies
import React from "react";
// import { NavLink } from "react-router-dom";
import { MdPlace, MdOutlineMoreHoriz } from "react-icons/md";
import { projects } from "../../store/constants";

export default function Index() {
  return (
    <div className="work pb-16">
      <div className="flex flex-col gap-2 items-start justify-start py-8 px-20">
        <h2 className="text-6xl font-semibold sm:text-5xl xs:text-5xl">
          Some of my work
        </h2>
        <h4 className="font-light">
          Check out more work over on&nbsp;
          <a
            href="https://github.com/ramadika"
            className="text-[#e74c3c] font-medium"
          >
            Github&#8599;
          </a>
        </h4>
      </div>
      <div className="transition-opacity duration-500 flex justify-start px-20 gap-8 w-full flex-wrap">
        {projects.map((project) => (
          <div key={project._id} className="w-96">
            <div className="flex flex-col items-start gap-4">
              {/* <NavLink
                to={`/more/${project._id}`}
                className="hover:scale-110 transition duration-500"
              > */}
              <img
                src={project.thumbnail}
                alt={project.title}
                className="object-cover w-96 h-72 border rounded-xl"
              />
              {/* </NavLink> */}
              <div>
                <h5 className="font-light text-xs flex items-center gap-1">
                  <MdPlace size={16} />
                  {project.place} | {project.year}
                </h5>
                <h5 className="font-semibold text-sm">{project.title}</h5>
                <MdOutlineMoreHoriz size={20} />
                <h6 className="text-xs font-light">[Role: {project.role}]</h6>
              </div>
              <div className="flex gap-1 flex-wrap">
                {project.skillset?.map((item, i) => (
                  <span className="text-xs border rounded-xl py-1 px-4">
                    {item}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
